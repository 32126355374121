<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">설문조사참여</h1>
        <div class="feature-body">
          <p>BMS 제품 및 관련 질환 관련 다양한 설문조사에 참여하고, 그 통계를 조회하실 수 있습니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">BMS 소식</a></li>
            <li class="breadcrumb">설문조사참여</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <!--리스트 영역 s-->
      <card-and-list
          title="설문조사참여"
          :last-update="$store.getters.getSurveyLastUpdate"
          :list="$store.getters.getSurveyList"
          :is-last="$store.getters.getSurveyListIsLast"
          @getMoreList="getMoreList"/>
      <!--리스트 영역 e-->
    </div>
  </main>
</template>

<script>
import CardAndList from "@/components/common/CardAndList";

export default {
  name: "SurveyEvent",
  components: {CardAndList},
  created() {
    this.$store.dispatch('fetchSurveyList', {
      query: {}
    })
  },
  methods: {
    getMoreList() {
      this.$store.dispatch('fetchSurveyList', {
        query: {
          page: this.$store.getters.getSurveyListPage + 1
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
